<template>
  <div class="details">
    <div class="roblist" v-for="(item,index) in list" :key="index" @click="to(item.id,item.device_name)">
      <p class="title">{{item.name}}</p>
      <p class="monitor">触发时间<span>{{item.time}}</span></p>
      <p class="monitor">PCL标识<span>{{item.pcl}}</span></p>
    </div>
  </div>

</template>

<script>
export default {
  name: "details",
  data() {
    return {
      searchValue: '',
      list: []
    };
  },
  created() {
    console.log(this.$route.query.list)
    this.list = this.$route.query.list
  },
  methods:{
    
  }
}
</script>

<style lang="less" scoped>
  .details{
    background: #f3f3f3;
    margin-bottom: 55px;
    padding:0 1%;
    padding-top: 5%;
    .van-search{
      background: #f3f3f3;
    }
    .van-search__content{
      background: #fff;
    }
    .roblist{
      padding: 0 1rem;
      border-bottom: 1px solid #ccc;
      background: #fff;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
      border-radius: 4px;
      padding-top: .4rem;
      margin-bottom: .4rem;
      p{
        margin: 0.35rem 0;
        font-size: 13px;
      }
      .monitor{
        span{
          margin-left: 10px;
          float: right;
        }
      }
      .title{
        font-size: 13px;
        font-weight: 800;
        border-bottom: 1px solid #ccc;
        padding-bottom: 15px;
        span{
          float: right;
          display: flex;
          align-items: center;
          b{
            display: block;
            width: 12px;
            height: 12px;
            background: red;
            border-radius: 50%;
            margin-right: 2px;
          }
          .now{
            background: linear-gradient(180deg,#33caba,#2baa5d)
          }
        }
      }
      .subtitle{
        font-size: 13px;
        color: #999;
        margin-bottom: 10px;
      }
      .warning{
        font-size: 12px;
        color: red;
      }
    }
    .roblist:nth-child(2){
      border-top: 1px solid #ccc;
    }
  }
</style>
